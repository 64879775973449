<template>
  <div>
    <!-- Modal to select or add new guarantor starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="selectGuarantor"
      role="dialog"
      aria-labelledby="gua"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <!-- <div class="modal-header"></div> -->
          <div class="top-header-guarantor">
            <div class="header-guarantor">
              <h5>Guarantor</h5>
              <p>Add or select from our smart guarantors.</p>
            </div>
            <div>
              <small class="close" @click="activeG = ''" data-dismiss="modal"
                >X</small
              >
            </div>
          </div>

          <div class="guarantor-boby">
            <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="background: none; border: 1px solid #D0D5DD;"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <input
                  style="border-left: none; padding-left: 0 !important"
                  @keyup="filterSmartGuarantor(searchsg)"
                  type="text"
                  class="form-control"
                  name="filter"
                  id="filter"
                  v-model="searchsg"
                  placeholder="Search for a smart  by name"
                />
                <!-- <div class="input-group-append">
                  <span class="input-group-text" style="cursor: pointer"
                    >Search</span
                  >
                </div> -->
              </div>
            </div>
            <div>
              <div class="guarantor" id="scrollbar-target">
                <div
                  @click="
                    activeG = index;
                    guarantorItem(item);
                  "
                  class="guarantor-holder"
                  :class="[activeG === index ? 'activeGuarantor' : '']"
                  v-for="(item, index) in userGuarantors"
                  :key="index"
                >
                  <small :class="[activeG === index ? 'activeName' : '']">{{
                    item.initials
                  }}</small>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div>
                      <h5>{{ item.name }}</h5>
                      <h6>{{ item.phone }}</h6>
                      <h4>{{ item.address }}</h4>
                    </div>
                    <div
                      class="select-holder"
                      :class="[activeG === index ? 'activeCheck' : '']"
                    >
                      <i v-if="index === activeG" class="fa fa-check"></i>
                    </div>
                  </div>
                </div>

                <div
                  @click="
                    activeG2 = index;
                    smartguarantorItem(item);
                  "
                  class="guarantor-holder"
                  :class="[activeG2 === index ? 'activeGuarantor' : '']"
                  v-for="(item, index) in guarantorsSmart.slice(0, 2)"
                  :key="'A' + index"
                >
                  <small :class="[activeG2 === index ? 'activeName' : '']">{{
                    item.initials
                  }}</small>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div>
                      <h5>{{ item.name }}</h5>
                      <h6>Smart Guarantor</h6>
                      <!-- <h6>{{ item.phone }}</h6>
                      <h4>{{ item.address }}</h4> -->
                      <div class="rating">
                        <!-- <img
                          style="width: 28px"
                          src="../assets/icons/star3.svg"
                          alt="raings"
                        /> -->
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                        <img src="../assets/icons/star2.svg" alt="raings" />
                      </div>
                    </div>
                    <div
                      class="select-holder"
                      :class="[activeG2 === index ? 'activeCheck' : '']"
                    >
                      <i v-if="index === activeG2" class="fa fa-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-guarantor">
            <p
              @click="closeG()"
              data-toggle="modal"
              data-target="#newguarantor"
            >
              Add your guarantor
            </p>
          </div>
          <div class="gurantor-btn">
            <button
              type="button"
              class="btn cancel"
              @click="activeG = ''"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="updateGurantorForRentFinancing()"
              type="button"
              class="btn continue"
              :disabled="guarantorId === '' || loading"
            >
              Submit
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loading"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to select or add new guarantor starts -->

    <!-- Modal to add new guarantor starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="newguarantor"
      role="dialog"
      aria-labelledby="newguarantor"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="top-header-guarantor">
            <div class="header-guarantor">
              <h5>Add new guarantor</h5>
              <p>Provide the details of your guarantor.</p>
            </div>
            <div>
              <small class="close" data-dismiss="modal">X</small>
            </div>
          </div>
          <div class="bvn-input">
            <div class="form-group">
              <label for="fname">Full name</label>
              <input
                style="text-transform: capitalize;"
                class="form-control"
                type="text"
                name="fname"
                id="fname"
                placeholder="Enter full name"
                v-model="guarantorfullname"
              />
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input
                style="text-transform: lowercase;"
                class="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                v-model="guarantoremail"
              />
            </div>
            <div class="form-group">
              <label for="address">Home address</label>
              <input
                style="text-transform: capitalize;"
                class="form-control"
                type="text"
                name="address"
                id="address"
                placeholder="Enter home address"
                v-model="guarantoraddress"
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone number</label>
              <input
                class="form-control"
                type="number"
                name="phone"
                id="phone"
                placeholder="Enter phone number"
                v-model="guarantorphone"
              />
            </div>
            <div class="form-group">
              <label for="phone">Relationship</label>
              <input
                class="form-control"
                type="text"
                name="relationship"
                id="relationship"
                placeholder="Enter relationshp"
                v-model="guarantorrelationship"
              />
            </div>
            <p class="note-text">
              Note:
              <span
                >Adding this guarantor automatically adds it to this property.
                However, you can change it before the property is
                approved.</span
              >
            </p>
          </div>

          <div class="gurantor-btn">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button
              :disabled="gLoader || loading"
              type="button"
              class="btn continue"
              @click="createGuarantor()"
            >
              Submit
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="gLoader"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to add new guarantor ends -->

    <!-- Success modal begins -->
    <div
      tabindex="-1"
      class="modal fade"
      id="rentsuccess"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <!-- <div class="modal-header"></div> -->
          <div class="modal-body confirmation-body">
            <img src="../assets/images/green-check-round.svg" alt="" />
            <h5>Guarantor Addition</h5>
            <p>
              Congratulations! Your you have added a guarantor to this property.
            </p>
          </div>
          <div class="modal-footer modal-button-confirm">
            <button type="button" class="btn okay" data-dismiss="modal">
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Success modal ends -->
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";

export default {
  name: "InfoPopup",
  components: {},
  props: ["action", "responseData", "closee"],
  data() {
    return {
      loading: false,
      reveal: false,
      diableBvnBtn: false,
      bvnLoader: false,
      showSubmit: false,
      hideDiv: false,
      gLoader: false,
      filterednow: false,
      searchsg: "",
      user: "",
      userGuarantors: [],
      guarantorsSmart: [],
      guarantorId: "",
      guarantorType: "sg",
      activeG: "",
      activeG2: "",
      guarantoraddress: "",
      guarantorrelationship: "",
      guarantorphone: "",
      guarantoremail: "",
      guarantorfullname: "",
      propertyId: ""
    };
  },
  watch: {
    searchsg: function(newValue) {
      // console.log("new value", newValue);
      if (newValue.length == 0 || newValue == "") {
        this.filterednow = false;
        // console.log("Hello")
        this.smartGuarantorsFiltered();
        // this.smartGuarantors();
      }
    }
  },
  created() {
    this.$root.$refs.Guarantor = this;
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.smartGuarantors();
  },
  methods: {
    active(data, mode) {
      // console.log("mode", mode);
      this.activeMode = data;
      this.mode = mode;
      this.paymode = mode.schedule;
      // this.calculations();
    },
    closeG() {
      $("#selectGuarantor")
        .removeClass("fade")
        .modal("hide");
      $("#newguarantor")
        .addClass("fade")
        .modal("show");
    },
    openGuarantorModal(id) {
      this.propertyId = id;
      $("#selectGuarantor")
        .addClass("fade")
        .modal("show");
    },
    async updateGurantorForRentFinancing() {
      this.loading = true;
      this.gLoader = true;
      // console.log("user id", this.user.id);
      try {
        await Api.postRequest(`update-rent-finance-smart-guarantor`, {
          propertyId: this.propertyId,
          guarantor_type: this.guarantorType,
          guarantor_id: this.guarantorId
        })
          .then(res => {
            this.loading = false;
            this.gLoader = false;
            // console.log("details of pro", res);
            if (res.data.success) {
              this.propDetails = res.data.property;
              $("#newguarantor")
                .removeClass("fade")
                .modal("hide");
              $("#selectGuarantor")
                .removeClass("fade")
                .modal("hide");
              $("#rentsuccess")
                .addClass("fade")
                .modal("show");
            }
            if (res.data.error) {
              this.showSubmit = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.showSubmit = false;
            this.loading = false;
            this.gLoader = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    async createGuarantor() {
      this.gLoader = true;
      if (
        this.guarantorfullname === "" ||
        this.guarantoremail === "" ||
        this.guarantoraddress === "" ||
        this.guarantorphone === "" ||
        this.guarantorrelationship === ""
      ) {
        this.gLoader = false;
        return this.$store.commit("setApiFailed", "All fields are rquired");
      }
      const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validRegex.test(this.guarantoremail)) {
        this.gLoader = false;
        return this.$store.commit("setApiFailed", "Invalid Email address!");
      }
      try {
        await Api.postRequest(`addnormalguarantor`, {
          address: this.guarantoraddress,
          relationship: this.guarantorrelationship,
          phone: this.guarantorphone,
          email: this.guarantoremail,
          fullname: this.guarantorfullname
        })
          .then(res => {
            // console.log("Guarantor response", res);
            this.gLoader = false;
            if (res.data.success) {
              this.guarantorId = res.data.gurantor.id;
              this.guarantorType = "ng";
              this.smartGuarantors();
              this.updateGurantorForRentFinancing();
              // this.$store.commit("setApiSuccess", "Guarantor added");
              // return this.$emit("action", res.data.gurantor);
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.gLoader = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    guarantorItem(data) {
      this.activeG2 = "";
      this.guarantorId = data.id;
      this.guarantorType = data.type;
      return this.$emit("action", data);
    },
    smartguarantorItem(data) {
      this.activeG = "";
      this.guarantorId = data.id;
      this.guarantorType = data.type;
      return this.$emit("action", data);
    },
    filterSmartGuarantor(string) {
      this.activeG = "";
      this.activeG2 = "";
      this.guarantorId = "";
      this.filterednow = true;
      const regexp = new RegExp(string, "i");
      const filtered = this.guarantorsSmart.filter(x => regexp.test(x.name));
      // console.log("Filter", filtered);
      this.guarantorsSmart = filtered;
      // this.smartGuarantors();
      if (this.guarantorsSmart.length == 0 || this.searchsg === "") {
        this.smartGuarantorsFiltered();
      }
    },
    async smartGuarantorsFiltered() {
      // if (this.searchsg === "") {
      //   this.filterednow = false;
      // } else {
      //   this.filterednow = true;
      // }
      try {
        await Api.getRequest(
          `fetchguarantorswhilerentfinancing/${this.user.id}`
        )
          .then(res => {
            // console.log("guarantors smart", res);
            if (res.data.success) {
              const guarantors = res.data.sg;
              guarantors.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                return this.guarantorsSmart.push(element);
              });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async smartGuarantors() {
      // if (this.searchsg === "") {
      //   this.filterednow = false;
      // } else {
      //   this.filterednow = true;
      // }
      try {
        await Api.getRequest(
          `fetchguarantorswhilerentfinancing/${this.user.id}`
        )
          .then(res => {
            // console.log("guarantors smart", res);
            if (res.data.success) {
              const guarantors2 = res.data.ng;
              guarantors2.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                this.userGuarantors.push(element);
              });
              const guarantors = res.data.sg;
              guarantors.forEach(element => {
                const y = element.name.split(" ");
                const iniName = element.name.charAt(0);
                const iniLname = y[y.length - 1].charAt(0);
                const x = iniName + iniLname;
                element.initials = x;
                this.guarantorsSmart.push(element);
              });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$primary: #0033ea;
$shelta-black: #000a2f;
$e7: #e7e7e7;
$shelta-gray: #555c74;
// $shelta-yellow: #ffb100;
// $shelta-gray: #2b3352;
// $shelta-gray2: #555c74;
// Guarantor modal starts
.top-header-guarantor {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0px;
  border-bottom: 1px solid #e4e7ec;
}
.header-guarantor {
  h5 {
    color: #2b3352;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 4px;
    text-align: left;
  }
  p {
    color: #555c74;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}
.close {
  font-size: 18px;
  border-radius: 8px;
  color: #555c74;
  padding: 10px;
  background: #f5f5f5;
  cursor: pointer;
  // width: 24px;
  // height: 24px;
}
.guarantor {
  overflow-y: scroll;
  max-height: 260px;
  overflow-x: hidden;
}
.guarantor-boby {
  padding: 20px 24px;
}
.guarantor-holder {
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 2px solid #e4e7ec;
  display: flex;
  padding: 16px;
  small {
    margin-bottom: 0;
    width: 40px;
    height: 40px;
    background: #f0f6ff;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $primary;
    border-radius: 50px;
    padding: 11px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  h5,
  h6,
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 4px;
  }
  h5 {
    color: #2b3352;
  }
  h6,
  h4 {
    color: #555c74;
  }
}
.rating {
  text-align: left;
  img {
    cursor: pointer;
    padding-right: 8px;
  }
}
.select-holder {
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  i {
    font-size: 10px;
    display: flex;
    padding: 2.4px;
    color: $white;
  }
}

.add-guarantor {
  padding: 0 24px;
  p {
    cursor: pointer;
    color: $primary;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}
.gurantor-btn {
  display: flex;
  padding: 32px 24px 24px;
  gap: 10px;
  button {
    width: 100%;
    height: 48px;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
  }
  .cancel {
    color: #2b3352;
    border: 1px solid #d0d5dd;
  }
  .continue {
    background: $primary;
    color: $white;
  }
}
.bvn-input {
  padding: 20px 24px;
  input {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    outline: 0;
    box-shadow: none;
  }
  small {
    color: $primary;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    cursor: pointer;
    margin-bottom: 6px;
  }
}
.activeGuarantor {
  border: 2px $primary;
  background: #dde4fc;
}
.activeName {
  background: $primary !important;
  color: $white !important;
}
.activeCheck {
  background: $primary;
}

.form-group > label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: $shelta-black;
  margin-bottom: 4px;
}

input[type="radio"] {
  // transform:scale(1.5);
  width: 20px;
  height: 20px;
}

.form-group {
  margin-bottom: 32px;

  select,
  input {
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $shelta-black;
    // text-transform: capitalize;
  }

  textarea {
    // text-transform: lowercase;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $shelta-black;
  }
}

input[type="select"],
input[type="text"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="password"] {
  height: 48px;
  // border: 0.7px solid #e5e5e5;
  border-radius: 5px;
  border: 1px solid $e7;
}

.note-text {
  font-size: 13px;
  color: #e13e3e;
  font-family: Lato;
  text-align: left;
}

#scrollbar-target::-webkit-scrollbar {
  display: none !important;
}

#scrollbar-target::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#scrollbar-target::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#scrollbar-target::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
#scrollbar-target::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

::placeholder {
  color: #ccced5;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

// Confirmation modaly
.confirmation-body {
  padding: 24px 40px 40px;

  @media only screen and (max-width: 599px) {
    .continueBtn {
      text-align: center;
    }

    padding: 24px 20px 40px;
  }

  img {
    padding-top: 8px;
    width: 64px;
  }

  h5 {
    padding-top: 24px;
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  p {
    color: $shelta-gray;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
  }
}

.modal-button-confirm {
  border-top: none;
  margin: 0 40px 40px;

  button:hover {
    background: #0124a5;
  }

  button {
    background: $primary;
    color: $white;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    height: 48px;
    width: 100%;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 599px) {
    margin: 0 20px 40px;
    padding: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Confirmation modaly
// Guarantor modal ends
</style>
