<template>
  <div>
    <div class="holder">
      <div class="dashboard_holder">
        <SmallNav />
        <div class="properties_count">
          <div>
            <img
              @click="backToDashboard()"
              src="../assets/images/thickbackarrow.svg"
              alt="back"
            />
            <h6 @click="backToDashboard()">
              Propertie(s)
              <span v-if="rented.length">({{ rented.length }})</span>
            </h6>
          </div>
          <div class="add_prop_btn">
            <button class="btn" @click="moveToAddProp()">Add Property +</button>
          </div>
        </div>
        <div v-if="hideDiv">
          <PageLoader></PageLoader>
        </div>
        <div v-else class="container wrap_row">
          <div class="row">
            <div
              class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4"
              v-for="(rent, index) in rented"
              :key="index"
            >
              <div
                class="for_rent_financing_approved"
                v-if="
                  rent.verified == 'YES' &&
                    rent.approved == 'YES' &&
                    rent.rentfinance == 'NO'
                "
              >
                <p>
                  <img src="../assets/images/verified-white.svg" alt="icon" />
                  Approved
                </p>
              </div>
              <div v-if="rent.rentFinancingObject">
                <div
                  class="for_rent_financing"
                  v-if="
                    rent.rentFinancingObject.approvedforrentfinancing == '1' &&
                      rent.rentfinance == 'YES' &&
                      rent.verified == 'YES'
                  "
                >
                  <p>
                    <img src="../assets/images/verified-white.svg" alt="icon" />
                    Rent Financing
                  </p>
                </div>
              </div>
              <!-- <div v-if="rent.rentFinancingObject">
                <div
                  class="hasRentApplied"
                  v-if="
                    rent.rentfinance == 'YES' &&
                      rent.rentFinancingObject.approvedforrentfinancing ==
                        '0' &&
                      rent.verified == 'YES' &&
                      rent.rentFinancingObject.appliedforrentfinancing == null
                  "
                >
                  <p>
                    <img
                      src="../assets/images/not-verified-prop.svg"
                      alt="icon"
                    />Not Applied For Rent Financing
                  </p>
                </div>
              </div> -->
              <div v-if="rent.rentFinancingObject">
                <div
                  class="hasRentApplied"
                  v-if="
                    rent.rentfinance == 'YES' &&
                      rent.rentFinancingObject.appliedforrentfinancing == '1' &&
                      rent.rentFinancingObject.approvedforrentfinancing == '0'
                  "
                >
                  <p>
                    <img
                      src="../assets/images/not-verified-prop.svg"
                      alt="icon"
                    />Applied For Rent Financing
                  </p>
                </div>
              </div>
              <div v-if="rent.rentFinancingObject">
                <div
                  class="hasRentApproved"
                  v-if="
                    (rent.rentfinance == 'YES' &&
                      rent.rentFinancingObject.appliedforrentfinancing ==
                        '0') ||
                      rent.rentFinancingObject.appliedforrentfinancing == null
                  "
                >
                  <p>
                    <img
                      src="../assets/images/not-verified-prop.svg"
                      alt="icon"
                    />Not Applied For Rent Financing
                  </p>
                </div>
              </div>

              <!-- v-if="rent.approved == 'NO' && rent.verified == 'NO'" -->
              <div
                class="hasRentApproved"
                v-if="rent.approved == 'NO' && rent.facilityverified == 'NO'"
              >
                <p>
                  <img
                    src="../assets/images/not-verified-prop.svg"
                    alt="icon"
                  />Property Not Verified
                </p>
              </div>
              <div v-if="rent.rentFinancingObject">
                <div
                  class="hasRentApproved"
                  v-if="
                    rent.rentFinancingObject.approvedforrentfinancing == '0' &&
                      rent.verified == 'YES' &&
                      rent.rentFinancingObject.appliedforrentfinancing == 1 &&
                      rent.rentFinancingObject.eligibility == 'approved'
                  "
                >
                  <p>
                    <img
                      src="../assets/images/not-verified-prop.svg"
                      alt="icon"
                    />Rent Financing Not Approved
                  </p>
                </div>
              </div>
              <!-- <div v-if="rent.rentFinancingObject">
                <div
                  class="hasRentEligibility"
                  v-if="
                    rent.rentfinance === 'YES' &&
                      rent.rentFinancingObject.approvedforrentfinancing ==
                        '0' &&
                      rent.verified == 'YES' &&
                      rent.rentFinancingObject.appliedforrentfinancing == 1 &&
                      rent.rentFinancingObject.eligibility == 'pending'
                  "
                >
                  <p>
                    <img
                      src="../assets/images/not-verified-prop.svg"
                      alt="icon"
                    />Rent Financing, Checking Eligibility
                    <i class="fa fa-spinner fa-spin ml-2 f-12 btn-loader"></i>
                  </p>
                </div>
              </div> -->
              <div class="grid_wrap">
                <img
                  class="verified_img"
                  v-if="rent.verified === 'YES' && rent.approved === 'NO'"
                  src="../assets/images/verify.svg"
                  alt="verified"
                />
                <!-- <div class="for_rent_financing" v-if="rent.approved == 'YES'"><p>Rent Financing</p></div> -->
                <div
                  class="days_left"
                  v-if="rent.approved == 'YES' && rent.rentfinance === 'YES'"
                >
                  <h3>{{ rent.daysAway }}</h3>
                  <h6>Days Left</h6>
                </div>
                <div class="menu_bar">
                  <!-- <span class="menu_text">Menu</span> -->
                  <div class="btn-group">
                    <button
                      class="btn dropdown-toggle btn_menu"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="dot_menu_holder">
                        <span>.</span><span>.</span><span>.</span>
                      </div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button
                        @click="navigateTo(1, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Payment Schedule
                      </button>
                      <hr />
                      <button
                        @click="navigateTo(2)"
                        class="dropdown-item"
                        type="button"
                      >
                        Facilities
                      </button>
                      <hr />
                      <button
                        v-show="
                          rent.hasaccountdetails === 'NO' ||
                            (rent.haslandlord === 'NO' &&
                              rent.rentfinance === 'YES' &&
                              rent.approved === 'NO')
                        "
                        @click="navigateTo(3, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Add Landlord/Manager
                      </button>
                      <button
                        v-if="
                          rent.hasaccountdetails == 'YES' &&
                            (rent.haslandlord == 'YES' ||
                              rent.rentfinance == 'YES') &&
                            rent.approved === 'NO'
                        "
                        @click="navigateTo(3, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Edit Landlord/Manager
                      </button>
                      <hr />
                      <!-- :disabled="rent.verified === 'NO'" -->
                      <!-- <div v-if="rent.rentFinancingObject">
                        <button
                          v-show="
                            (rent.rentfinance === 'YES' &&
                              rent.rentFinancingObject
                                .approvedforrentfinancing == '0' &&
                              rent.verified === 'YES' &&
                              rent.rentFinancingObject
                                .appliedforrentfinancing == null) ||
                              rent.rentFinancingObject
                                .appliedforrentfinancing == '0' ||
                              rent.rentFinancingObject.eligibility == 'pending'
                          "
                          @click="navigateTo(4, rent)"
                          class="dropdown-item"
                          type="button"
                        >
                          Apply for rent financing
                        </button>
                      </div> -->
                      <div v-if="rent.rentFinancingObject">
                        <button
                          v-show="
                            (rent.rentfinance === 'YES' &&
                              rent.rentFinancingObject
                                .approvedforrentfinancing == '0' &&
                              rent.rentFinancingObject
                                .appliedforrentfinancing == null) ||
                              rent.rentFinancingObject
                                .appliedforrentfinancing == '0'
                          "
                          @click="navigateTo(4, rent)"
                          class="dropdown-item"
                          type="button"
                        >
                          Apply for rent financing
                        </button>
                      </div>
                      <hr />
                      <button
                        @click="navigateTo(6, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Schedule Rent With SAFE
                      </button>
                      <hr />
                      <button
                        v-show="
                          rent.rentfinance === 'YES' &&
                            rent.rentFinancingObject.approvedforrentfinancing ==
                              '0'
                        "
                        @click="trigerGuarantorModal(rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Add/Edit Guarantor
                      </button>
                      <hr />
                      <button
                        v-if="
                          (rent.rentfinance === 'YES' &&
                            rent.approved === 'NO') ||
                            rent.hasaccountdetails === 'NO'
                        "
                        @click="navigateTo(5, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Edit Property
                      </button>
                      <hr />
                      <button
                        v-if="
                          rent.rentfinance === 'YES' && rent.approved === 'NO'
                        "
                        @click="navigateTo(7, rent)"
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#deletePropertyConfirm"
                      >
                        Delete Property
                      </button>
                      <hr />
                      <button
                        @click="navigateTo(8, rent)"
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#deletePropertyConfirm"
                      >
                        Property Details
                      </button>
                      <!-- <hr />
                      <button
                        v-if="rent.rentfinance === 'YES'"
                        @click="navigateTo(10, rent)"
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#deletePropertyConfirm"
                      >
                        Apply for RENT
                      </button> -->

                      <!-- <button
                        
                        @click="navigateTo(4, rent)"
                        class="dropdown-item"
                        type="button"
                      >
                        Apply for rent financing
                      </button> -->
                      <!-- <button
                        class="dropdown-item"
                        type="button"
                        data-toggle="modal"
                        data-target="#check"
                      >
                        Modal click
                      </button> -->
                    </div>
                  </div>
                </div>
                <img
                  v-if="rent.oneimageurl"
                  class="cover_img"
                  :src="rent.oneimageurl"
                  alt="property-img"
                />
                <img
                  v-else
                  class="cover_img"
                  src="../assets/images/empty-prop-blue.svg"
                  alt="property-img"
                />
                <div class="base">
                  <div class="title_wrap">
                    <h4>{{ rent.title }}</h4>
                    <p v-if="rent.state">{{ rent.city }}, {{ rent.state }}</p>
                    <small>PIN: {{ rent.pin }}</small>
                  </div>
                  <div class="price_holder">
                    <div class="selected_price_holder">
                      <div>
                        <span class="check_main" v-if="rent.approved == 'YES'">
                          <span class="check"></span>
                        </span>
                        <div v-if="rent.approved == 'NO'"></div>
                        <div
                          class="real_amount"
                          v-if="
                            rent.rentfinance == 'YES' && rent.approved == 'YES'
                          "
                        >
                          <h5 class="selected_amount">
                            &#8358;{{
                              Number(rent.paynowvalue).toLocaleString()
                            }}
                          </h5>
                          <p>{{ rent.tenantmodeofpayments }}</p>
                        </div>
                        <div
                          class="real_amount"
                          v-if="
                            rent.rentfinance == 'NO' && rent.approved == 'YES'
                          "
                        >
                          <h5 class="selected_amount">
                            &#8358;{{
                              Number(rent.paynowvalue).toLocaleString()
                            }}
                          </h5>
                          <p>{{ rent.tenantmodeofpayments }}</p>
                        </div>
                        <div class="real_amount" v-if="rent.approved == 'NO'">
                          <h5 class="selected_amount price_check">
                            &#8358;{{ Number(rent.price).toLocaleString() }}
                          </h5>
                          <p>
                            Annaully
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="three_buttons" :disabled="rent.verified === 'NO'">
                    <button
                      v-if="
                        rent.approved === 'YES' && rent.rentfinance === 'NO'
                      "
                      class="btn"
                      @click="priceToPay(rent.paynowvalue, null, rent)"
                    >
                      PAY NOW
                    </button>
                    <button
                      v-if="
                        rent.approved === 'YES' && rent.rentfinance === 'YES'
                      "
                      class="btn"
                      @click="
                        priceToPay(
                          rent.paynowvalue,
                          rent.rentFinancingObject.rentfinanceagreement,
                          rent
                        )
                      "
                    >
                      PAY NOW
                    </button>
                    <button
                      v-if="rent.approved === 'NO'"
                      class="btn"
                      @click="unverifiedProperty(rent.pin, rent.rentfinance)"
                    >
                      PAY NOW
                    </button>
                    <button
                      class="btn"
                      @click="switchPaymentPlan(rent.switchid)"
                      :disabled="rent.switchable === 'NO' || !rent.switchable"
                    >
                      <img src="../assets/images/switch.svg" alt="switch" />
                    </button>
                    <button class="btn" disabled>
                      <img src="../assets/images/swap-color.svg" alt="swap" />
                    </button>
                  </div>
                  <div class="more_details">
                    <h6>MORE DETAILS</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div else class="empty__state" v-if="rented.length === 0 && !hideDiv">
          <div class="empty__div">
            <img src="../assets/images/empty-state.svg" alt="" />
            <p>No property to view at the moment</p>
          </div>
        </div>

        <!-- Delete Property modal starts -->
        <div class="modal" tabindex="-1" id="deletePropertyConfirm">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header title-modal2">
                <h5 class="modal-title">Delete Property</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="confrim-delete-text">
                  Are you sure you want to delete this property?
                </p>
              </div>
              <div class="confirm-delete">
                <button
                  class="btn cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button class="btn delete" @click="deleteProperty">
                  Delete
                  <i
                    class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                    v-if="deleteLoader"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Delete Property modal ends -->

        <!-- Rent finance check modal data begin-->
        <div class="modal" tabindex="-1" id="check">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Instructions:</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body instruction_body">
                <h6>Please provide the following, if not already provided.</h6>
                <div>
                  <p>
                    <img
                      v-show="hasLandlord === 'YES'"
                      src="../assets/images/verify.svg"
                      alt="submitted"
                    />
                    <img
                      v-show="hasLandlord === 'NO'"
                      class="notsupported"
                      src="../assets/images/red-wrong.svg"
                      alt="notsubmitted"
                    />
                    Landlord/Property Manager Details
                  </p>
                  <p>
                    <img
                      v-show="hasAccountDetails === 'YES'"
                      src="../assets/images/verify.svg"
                      alt="submitted"
                    />
                    <img
                      v-show="hasAccountDetails === 'NO'"
                      class="notsupported"
                      src="../assets/images/red-wrong.svg"
                      alt="notsubmitted"
                    />
                    Landlord/Property Manager Account Details
                  </p>
                </div>
              </div>
              <div class="modal-footer check_btn">
                <button type="button" class="btn" data-dismiss="modal">
                  Cancel
                </button>
                <button type="button" class="btn" @click="moveToAddLandLord">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Rent finance check modal data end -->

        <!-- Rent finance check modal data begin-->
        <div class="modal" tabindex="-1" id="cantpay">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Information:</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body notverified_body">
                <h6>
                  This property has not been approved
                  <span class="rent_condition" v-if="rentfinacing == 'YES'"
                    >for rent finacing</span
                  >, contact
                  <span>Shelta</span>
                </h6>
                <p>Property PIN: {{ propPin }}</p>
                <a href="https://api.whatsapp.com/send?phone=2348061796909"
                  ><img
                    src="../assets/images/whatsApp-blue.svg"
                    alt="whatsapp"
                  /><span>(+234)8061796909</span></a
                >
              </div>
              <!-- <div class="modal-footer check_btn">
                <button type="button" class="btn" data-dismiss="modal">
                  Cancel
                </button>
                
              </div> -->
            </div>
          </div>
        </div>
        <!-- Rent finance check modal data end -->
      </div>
    </div>
    <!-- <component class="more-content" :is="PaymentModal"></component> -->
    <paymentModal
      @paystackReference="dataFromChild($event)"
      @action="dataFromChild($event)"
      :dataForPayment="modalData"
      :responseData="resData"
      v-model="paymentM"
    />
    <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
    <subscriptionModal />

    <guarantorModal @action="dataFromGuarantor($event)"></guarantorModal>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import paystack from "vue-paystack";
// import { staticdata } from "../../src/data";
import paymentModal from "../components/PaymentModal.vue";
import guarantorModal from "../components/GuarantorModal.vue";
import subscriptionModal from "../components/SubscriptionModal.vue";

export default {
  name: "Rented",
  components: {
    SmallNav,
    // paystack,
    PageLoader,
    paymentModal,
    subscriptionModal,
    guarantorModal
  },
  data() {
    return {
      // fulldata: {
      //   user_object: {},
      // },
      loader: false,
      paymentData: "",
      fulldata: "" || {
        user_object: {}
      },
      rented: [],
      user: "",
      id: "",
      hideDiv: false,
      deleteLoader: false,
      error: false,
      uploading: false,
      fileselected: false,
      progress: 0,
      file: "",
      filePath: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      userid: "",
      percentageFull: false,
      payableAmount: "",
      hasLandlord: "",
      hasAccountDetails: "",
      dataDetails: "",
      propPin: "",
      rentfinacing: "",
      // PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      full_name: "",
      reference: "",
      modalData: {},
      paymentM: "",
      resData: "",
      propertyObject: ""
      // amount: ""
    };
  },
  // created() {
  //   this.$root.$refs.p = this;
  // },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    this.getUserProfile();
    this.moreTenantData();
    this.fetchTenantRentedProperty();
    if (localStorage.getItem("scheduleSafe")) {
      localStorage.removeItem("scheduleSafe");
    }
    // console.log(
    //   "Navigation type",
    //   performance.getEntriesByType("navigation")[0].type
    // );
  },
  methods: {
    // test() {
    //   this.$root.$refs.P.rentedPropertyCount();
    //   console.log("Click from another component");
    // },
    membership() {
      this.$root.$refs.D.openSubModal();
    },
    trigerGuarantorModal(rent) {
      this.$root.$refs.Guarantor.openGuarantorModal(rent.id);
    },
    dataFromGuarantor(data) {
      console.log("Guarantor momdal", data);
    },
    trigerRentPayment(propInfo) {
      // this.resData = "loading";
      this.propertyObject = propInfo;
      this.fundLoader = true;
      this.modalData.amount = 0;
      this.modalData.payableAmount = Number(propInfo.paynowvalue);
      this.modalData.subtitle = "Payment Amount";
      this.modalData.paymentType = "rentpayment";
      this.modalData.headerCaption = "RENT PAYMENT";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.paymentURL = "";
      this.$root.$refs.A.openPaymentOptionsModal(propInfo);
    },

    dataFromChild(data) {
      // console.log("Data from child", data);
      // if (
      //   data.action === true &&
      //   this.modalData.paymentType === "rentpayment" &&
      //   data.type === "paystack"
      // ) {
      //   this.processPaymentPaystck(data.ref);
      // }
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "payfromsafe"
      ) {
        this.payMyRentWithSafe();
      }
      // if ( data.action === true &&
      //   this.modalData.paymentType === "rentpayment" &&
      //   data.type === "paybanktransferfromsafe") {}
      // this.reference = data;
      // this.setupScheduleFinally();
    },

    processPayment(res) {
      // console.log("clikedd", res);
      const ref = res.reference;
      if (res.status === "success") {
        $("#fundme")
          .removeClass("fade")
          .modal("hide");
        Api.postRequest(`paynow`, {
          id: this.propertyObject.switchid,
          amount: Number(this.modalData.payableAmount),
          referencecode: ref
        })
          .then(res => {
            // console.log("rented payment response", res);
            if (res.data.success === "success") {
              this.fetchTenantRentedProperty();
              return this.$store.commit(
                "setApiSuccess",
                "Payment is successful"
              );
            }
          })
          .catch(err => {
            console.log("err", err);
            return this.$store.commit("setApiFailed", "Error occured");
          });
      }
      // console.log("refrence key", res, ref);
      // window.alert("Refrence key:", this.reference);
    },
    // Paystack close function or cancel payment
    close: () => {
      console.log("You closed checkout page");
    },
    // If pay from safe is selected
    async payMyRentWithSafe() {
      // alert("pay with safe");
      this.resData = "loading";
      this.loader = true;
      if (
        this.propertyObject.switchid === "" ||
        this.modalData.payableAmount === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Payment parameters missing");
      } else {
        try {
          // payrentviasafe
          await Api.postRequest(`paynowwithsafe`, {
            id: this.propertyObject.switchid,
            amount: Number(this.modalData.payableAmount)
          })
            .then(res => {
              // console.log("Rent Payment from safe response", res);
              this.loader = false;
              this.resData = "response";
              if (res.data.success) {
                this.fetchTenantRentedProperty();
                this.moreTenantData();
                return this.$store.commit(
                  "setApiSuccess",
                  "Payment is successful"
                );
              }
              this.resData = "";
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              this.resData = "response";
              console.log("err", err);
              // return this.$store.commit("setApiFailed", "Error occured");
            });
        } finally {
          // console.log("closed")
        }
      }
    },
    priceToPay(data, agreement, rent) {
      // console.log("Rentttttttttttttttt", rent);
      this.payableAmount = data;
      Api.pushPropertyDetails(rent);
      if (rent.rentfinance === "NO") {
        this.trigerRentPayment(rent);
        // return $("#fundme")
        //   .addClass("fade")
        //   .modal("show");
      } else if (agreement === "YES" && rent.rentfinance === "YES") {
        this.trigerRentPayment(rent);
        // $("#fundme")
        //   .addClass("fade")
        //   .modal("show");
      } else {
        this.$router
          .push({
            path: `/dashboard/rentfinancingagreement`
          })
          .catch(() => {});
      }
    },
    switchPaymentPlan(id) {
      if (id) {
        this.$router
          .push({
            path: `/dashboard/switch/${id}`
          })
          .catch(() => {});
      }
    },
    backToDashboard() {
      this.$router
        .push({
          path: `/dashboard/tenant/`
        })
        .catch(() => {});
    },
    moveToAddProp() {
      this.$router.push({
        path: `/dashboard/addpropertytenant`
      });
    },
    unverifiedProperty(pin, finacing) {
      this.propPin = pin;
      this.rentfinacing = finacing;
      $("#cantpay")
        .addClass("fade")
        .modal("show");
      //  $("#check")
      //   .removeClass("fade")
      //   .modal("hide");
    },
    moveToAddLandLord() {
      Api.pushPropertyDetails(this.dataDetails);
      $("#check")
        .removeClass("fade")
        .modal("hide");
      this.$router.push({
        path: `/dashboard/addlandlord`
      });
    },
    navigateTo(data, propData) {
      this.dataDetails = propData;
      // console.log("aprrovedddd1", propData);
      if (data === 1) {
        if (propData.approved === "YES") {
          // console.log("aprrovedddd", propData.approved);
          Api.pushPropertyDetails(propData);
          this.$router.push({
            path: `/dashboard/paymentschedule`
          });
        } else {
          $("#cantpay")
            .addClass("fade")
            .modal("show");
        }
      } else if (data === 2) {
        this.$router.push({
          path: `/dashboard/rented`
        });
      } else if (data === 3) {
        Api.pushPropertyDetails(propData);
        this.$router.push({
          path: `/dashboard/addlandlord`
        });
      } else if (data === 4) {
        this.hasLandlord = propData.haslandlord;
        this.hasAccountDetails = propData.hasaccountdetails;
        // this.$router.push({
        //   path: `/dashboard/applyrentfinancing/${propData.pin}`
        // });
        // console.log("pinnnnnnnnnn", propData);
        // if (
        //   propData.haslandlord === "NO" ||
        //   propData.hasaccountdetails === "NO"
        // ) {
        //   $("#check")
        //     .addClass("fade")
        //     .modal("show");
        // }
        // else {
        // Api.pushPropertyDetails(propData);
        if (this.user.subscribed === "YES") {
          $("#check")
            .removeClass("fade")
            .modal("hide");
          this.$router.push({
            path: `/dashboard/applyrentfinancing/${propData.pin}`
          });
        } else {
          this.membership();
          // this.$router.push({
          //   path: `/dashboard/subscription`
          // });
        }
        // }
      } else if (data === 5) {
        Api.pushPropertyDetails(propData);
        this.$router.push({
          path: `/editpropertytenant`
        });
      } else if (data === 6) {
        Api.pushPropertyDetails(propData);
        localStorage.setItem("scheduleSafe", propData.id);
        this.$router.push({
          path: `/dashboard/newproject`
        });
      } else if (data === 8) {
        Api.pushPropertyDetails(propData);
        // localStorage.setItem("scheduleSafe", propData.id);
        this.$router.push({
          path: `/dashboard/renteddetails`
        });
      }
      // else if (data === 10) {
      //   // Api.pushPropertyDetails(propData);
      //   // localStorage.setItem("scheduleSafe", propData.id);
      //   this.$router.push({
      //     path: `/dashboard/applyrentfinancing/${propData.pin}`
      //   });
      // }
      // else if (data === 7) {
      //   this.propIdToDelete = propData;
      //   // this.deteleProperty(propData.id);
      // }
      // .catch(() => {});
    },
    // payFromBank: function() {
    //   $("#fundme")
    //     .removeClass("fade")
    //     .modal("hide");
    //   $("#showpayment")
    //     .addClass("fade")
    //     .modal("show");
    // },
    payModal: function() {
      $("#propertyPin")
        .removeClass("fade")
        .modal("hide");
      $("#showpfundmeayment")
        .addClass("fade")
        .modal("show");
    },
    deleteProperty() {
      this.deleteLoader = true;
      Api.postRequest(`deleteproperty`, {
        propertyid: this.dataDetails.id
      })
        .then(res => {
          this.deleteLoader = false;
          if (res.data.success) {
            this.$store.commit("setApiSuccess", res.data.success);
            this.fetchTenantRentedProperty();
            this.$root.$refs.P.rentedPropertyCount();
            $("#deletePropertyConfirm")
              .removeClass("fade")
              .modal("hide");
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
          this.deleteLoader = false;
        });
    },
    async fetchTenantRentedProperty() {
      this.hideDiv = true;
      // this.loader = true;
      try {
        await Api.getRequest(`tenantinvolvedproperties/${this.id}`)
          .then(res => {
            // if (res.data.error) {
            //   this.hideDiv = false;
            // }
            // console.log("Rented", res);
            this.hideDiv = false;
            this.rented = res.data.properties;
            this.rented.map(element => {
              const rentDueDate = element.rentenddate;
              const rentdate = new Date(rentDueDate);
              const currentDate = new Date();
              const timeDiff = rentdate.getTime() - currentDate.getTime();
              const diff = timeDiff / (1000 * 3600 * 24);
              const daysDiff = Math.round(diff);
              if (daysDiff <= 0) {
                element.daysleft = 0;
              } else {
                element.daysleft = parseInt(daysDiff);
              }
            });
            // console.log("rented props", this.rented);
          })
          .catch(err => {
            console.log(err);
            this.hideDiv = false;
            // this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("User profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return (window.location.href = "https://myshelta.com/login");
        }
        this.user = res.data.user;
        this.fulldata = res.data.fulldata;
        // console.log("full data", this.user);
        // this.emergency = this.fulldata.emergency_details;
      });
    },
    // switchView(event) {
    //   if (event === 1) {
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("twoo").style.display = "block";
    //     document.getElementById("threee").style.display = "none";
    //     this.headerText1 = false;
    //     this.headerText2 = true;
    //     this.headerText3 = false;
    //   } else if (event === 2) {
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("threee").style.display = "block";
    //     this.headerText1 = false;
    //     this.headerText2 = false;
    //     this.headerText3 = true;
    //   } else if (event === 0) {
    //     document.getElementById("onee").style.display = "block";
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("threee").style.display = "none";
    //     // this.headerText2 = false;
    //   } else {
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("threee").style.display = "block";
    //   }
    // },

    // selectFile() {
    //   const file = this.$refs.file.files[0];
    //   this.file = file;
    //   this.filePath = URL.createObjectURL(file);
    //   this.fileName = file.name;
    //   // console.log("fileeeeeeeeeeee", this.file);
    //   if (this.file) {
    //     this.fileselected = true;
    //   } else {
    //     this.fileselected = false;
    //   }
    // },
    // async sendFile() {
    //   // this.switchView(2)
    //   const file = this.$refs.file.files[0];
    //   // this.file = file;
    //   if (!this.file) {
    //     // this.loaderM = false;
    //     return this.$store.commit("setApiFailed", "No file was selected!");
    //   }
    //   const formData = new FormData();
    //   formData.append("file", this.file);
    //   formData.append("amount", this.paymentData.nextpayment);
    //   formData.append("id", this.paymentData.nextpaymentid);
    //   try {
    //     this.uploading = true;
    //     await Api.postRequest(`paynow2`, formData, {
    //       onUploadProgress: e =>
    //         (this.progress = Math.round((e.loaded * 100) / e.total))
    //     })
    //       .then(res => {
    //         // console.log("Uploaded file", res);
    //         // this.$store.commit("setApiWarning", '');
    //         this.$store.commit("setApiSuccess", "Success");
    //         this.switchView(2);
    //         this.uploading = false;
    //         if (res.data.success) {
    //           this.moreTenantData();
    //         }
    //         // this.message = "File has been uploaded";
    //         // this.file = '';
    //         // this.error = false;
    //       })
    //       .catch(err => {
    //         // console.log(err);
    //         // this.loader = false;
    //         this.uploading = true;
    //       });
    //   } finally {
    //     // console.log("Closed connection");
    //   }
    // },
    moreTenantData: function() {
      Api.getRequest(`fetchmoretenantdatabyid/${this.id}`)
        .then(res => {
          // console.log("More data", res);
          this.paymentData = res;
          window.localStorage.setItem(
            "currentpropertydetails",
            JSON.stringify(res.data)
          );
          this.paymentData = JSON.parse(
            window.localStorage.getItem("currentpropertydetails")
          );
          // console.log("datttttttttt", this.paymentData);
        })
        .catch(err => {
          console.log(err);
          // this.loader = false;
          // this.uploading = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

button:disabled {
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.81);
  height: inherit;
  border-radius: 10px;
}
.empty__div {
  padding: 1rem;
  img {
    width: 100%;
    max-width: 470.58px;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    margin-bottom: 0;
  }
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: #000000;
  }
}

// .for_rent_financing {
//   margin: auto;
//   // top: -4%;
//   // left: 36%;
//   // position: absolute;
//   width: 26%;
//   // z-index: 10;
// }
.for_rent_financing {
  background: $yellow;
  margin: auto;
  // width: 57%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: fit-content;
  // padding: 0 40px;
  padding: 0px 20px;
  position: relative;
  p {
    // background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
    // background: $yellow;
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    padding: 7px 10px;
    // width: 103px;
    // height: 24px;
    font-size: 9px;
    line-height: 15px;
    // content: "";
  }
  img {
    width: 9%;
    position: absolute;
    left: 8px;
    bottom: 24%;
  }
}

.for_rent_financing_approved {
  background: #3ec250;
  margin: auto;
  // width: 57%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: fit-content;
  // padding: 0 40px;
  padding: 0px 20px;
  position: relative;
  p {
    // background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
    // background: $yellow;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    padding: 5px 10px;
    // width: 103px;
    // height: 24px;
    padding: 7px 10px;
    font-size: 9px;
    line-height: 15px;
    // content: "";
  }
  img {
    width: 9%;
    position: absolute;
    left: 8px;
    bottom: 24%;
  }
}

.hasRentApproved {
  background: #ea0000;
  margin: auto;
  // width: 57%;
  width: fit-content;
  // padding: 0 40px;
  padding: 0px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
  p {
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    padding: 7px 10px;
    line-height: 15px;
    margin-bottom: 0;
  }
  img {
    // width: 7%;
    position: absolute;
    left: 8px;
    bottom: 24%;
  }
}
.hasRentEligibility {
  background: #6610f2;
  margin: auto;
  // width: 57%;
  width: fit-content;
  // padding: 0 40px;
  padding: 0px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
  p {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    padding: 7px 10px;
    font-size: 9px;
    line-height: 15px;
    margin-bottom: 0;
  }
  img {
    // width: 7%;
    position: absolute;
    left: 8px;
    bottom: 24%;
  }
}
.hasRentApplied {
  background: rgba(0, 51, 234, 0.2);
  margin: auto;
  // width: 57%;
  width: fit-content;
  // padding: 0 40px;
  padding: 0px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
  p {
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    padding: 7px 10px;
    line-height: 15px;
    margin-bottom: 0;
  }
  img {
    // width: 7%;
    position: absolute;
    left: 8px;
    bottom: 24%;
  }
}
.dot_menu_holder {
  position: absolute;
  left: 10%;
  top: 5%;
  transform: rotate(270deg);
  span {
    width: 7px;
    height: 7px;
    color: $primary;
  }
}

.menu_text {
  color: $primary;
  font-size: 12px;
  margin-right: 3px;
  font-family: Gotham;
  font-style: normal;
}
.btn_menu::after {
  display: none !important;
}
.menu_bar {
  position: absolute;
  right: 5%;
  top: 2%;
}
.btn_menu {
  position: relative;
  width: 28px;
  height: 28px;
  background: $secondary;
  border-radius: 50%;
  border: 0.5px solid $tatiary;
  outline: 0;
  box-shadow: none;
}

.dropdown-menu-right {
  button {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    padding: 1rem 1.5rem;
    margin-top: 0 !important;
    // display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
    outline: 0;
    box-shadow: none;
  }
  hr {
    // margin: 0.5rem 0;
    margin: 0;
  }
}
.dropdown-menu-right {
  padding: 0;
  button:hover {
    background: $primary;
    color: $secondary;
  }
}

.holder {
  height: 100%;
}

.properties_count {
  // text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  div:first-of-type {
    text-align: left;
  }
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    cursor: pointer;
    span {
      color: $primary;
      font-size: 20px;
    }
  }
}

.add_prop_btn {
  button {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    box-shadow: none;
    outline: 0;
  }
}

.grid_wrap {
  background: $secondary;
  // max-width: 329px;
  width: 100%;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 20px;
  position: relative;
  .cover_img {
    width: 100%;
    // max-width: 329px;
    // max-height: 216px;
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    max-height: 216px;
    min-height: 216px;
    height: 100%;
  }
}

.verified_img {
  position: absolute;
  left: 4%;
  top: 3%;
}

.days_left {
  width: 67px;
  height: 67px;
  position: absolute;
  background: $secondary;
  border-radius: 50%;
  padding-top: 18px;
  margin: 7px;
  border: 1px solid #768fe9;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
}

.base {
  border-top: none !important;
  border: 0.2px solid #768fe9;
}

.hasRentFinancing {
  border-top: 1px solid $yellow;
  border-right: 1px solid $yellow;
  border-left: 1px solid $yellow;
}
.title_wrap {
  padding: 15px 20px 0 20px;
  border-bottom: 0.2px solid #768fe9;
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    display: flex;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #6c6c6c;
    display: flex;
    margin-bottom: 10px;
  }
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #6c6c6c;
    display: flex;
    margin-bottom: 10px;
  }
}

.price_holder {
  display: flex;
  justify-content: space-between;
  padding: 14px 18px 0 18px;
  align-items: center;
  border-bottom: 0.2px solid #768fe9;
}

.check_main {
  border-radius: 50%;
  background: #2fc643;
  padding: 3px 10.5px;
  top: -14px;
  position: relative;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 17px;
  width: 9px;
  border-bottom: 3px solid $secondary;
  border-right: 3px solid $secondary;
}

.selected_price_holder {
  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
      font-family: Gotham;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #6c6c6c;
      // padding-left: 12px;
    }
  }
}
.selected_amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #2fc643;
  margin-bottom: 5px;
}
.price_check {
  color: $black !important;
}
.real_amount {
  text-align: left;
  margin-left: 5px;
}

.per_anuum {
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: $black;
    margin-bottom: 5px;
  }
  p {
    font-family: Gotham;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #6c6c6c;
  }
}

.three_buttons {
  background: #e6e7f3;
  border-bottom: 0.2px solid #768fe9;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  button:first-child {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: center;
    color: $secondary;
    width: 86.31px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
  }
  button:first-child:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(2) {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    max-width: 86.31px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    img {
      width: 100%;
    }
  }
  button:nth-child(2):hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(3):hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  button:nth-child(3) {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 5px;
    width: 86.31px;
    box-shadow: none;
    outline: 0;
    // height: 34px;
    img {
      width: 100%;
    }
  }
}

.more_details {
  display: none;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
    margin: 20px;
    cursor: pointer;
  }
}

.check_btn {
  margin: auto;
  button:first-child {
    background: $yellow;
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    outline: 0;
    box-shadow: none;
  }
  button:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
}

.instruction_body {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    margin-bottom: 20px;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    font-size: 15px;
    .notsupported {
      max-width: 5%;
      width: 100%;
    }
  }
}
.close {
  box-shadow: none;
  outline: 0;
}
.rent_condition {
  color: inherit !important;
  font-size: inherit !important;
}
.notverified_body {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    text-align: left;
    margin-bottom: 10px;
    line-height: 30px;
    span {
      color: $primary;
      font-weight: 600;
    }
  }
  p {
    font-family: Gotham;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
  }
  span {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    font-size: 20px;
    color: #2fc643;
  }
  a {
    text-decoration-color: #2fc643;
    img {
      width: 6%;
      margin-right: 10px;
    }
  }
}

.close_bank {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

// Modal to confirm delete starts
.confrim-delete-text {
  font-family: Gotham;
  font-size: 20px;
  font-weight: normal;
  color: $black2;
}

.title-modal2 {
  background: $primary;
  padding: 1rem 2rem;
  h5 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
}

.confirm-delete {
  display: flex;
  justify-content: center;
  button {
    font-family: Gotham;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 20px;
    outline: 0;
    box-shadow: none;
  }
  .cancel {
    color: $primary;
    border: 1px solid $primary;
    margin-right: 10px;
  }
  .delete {
    color: $secondary;
    background: #ea0000;
  }
}
// Modal to confirm delete ends

@media screen and (max-width: 599px) {
  .grid_wrap {
    margin-bottom: 50px;
  }
  .days_left {
    width: 55px;
    height: 55px;
    padding-top: 10px;
    h3 {
      font-size: 13px;
    }
    h6 {
      font-size: 9px;
    }
    // bottom: -32%;
    // padding-top: 13px;
  }
  .add_prop_btn {
    button {
      font-size: 10px;
    }
  }

  .properties_count {
    h6 {
      font-size: 14px;
    }
  }

  .empty__div {
    p:nth-child(3) {
      font-size: 13px;
    }
  }

  .instruction_body {
    p {
      font-size: 12px;
    }
  }
  .notverified_body {
    h6 {
      text-align: justify;
    }
  }
}

@media screen and (max-width: 768px) {
  .landlord_form {
    form {
      margin: 78px 20px;
    }
  }
}
</style>
